import React from 'react';

const Hero = () => {
    return (
        <div className='hero py-5'>
            <div className="container py-5">
                <h1 className='txt-primary'>Dr.Newton Kumar Varma</h1>
                <p className='sub-title'>Specialized in <b>Dental Surgery </b>and <b>Pharmacy</b></p>

                <p className='header-body'>

I am grateful that you trust me with your health and dental care needs. With my expertise in both <b>BDS</b> and <b>pharmacy</b>, I strive to provide a comprehensive approach to your well-being. My goal is to ensure that you receive the highest quality care in dental surgery, medication management, and overall health guidance. Alongside my main services, I emphasize a customer-focused approach, ensuring that your care experience is as supportive and seamless as possible.
<b/>
<b/>
<p>If you have any dental or pharmacy-related concerns, or if you are considering surgery, <b>please feel free to book an appointment</b>. I am here to provide personalized care and answer any questions you may have about your treatment options.</p>
</p>
                <a href='#appointment' className='btn btn-primary mt-2'>Book Appointment</a>
            </div>
        </div>
    );
};

export default Hero;
import React from 'react';

const About = () => {
    return (
        <div>
            <div className="container py-5">
                <h1 className='txt-primary'><b>Newton Kumar varma </b> </h1>
                <p>Hello, I’m <b>Dr. Newton Kumar Verma</b>, a dedicated <b>BDS</b> doctor and certified <b>pharmacist</b> with a passion for advancing patient care through both dental and pharmaceutical expertise. With a strong background in dental sciences and an in-depth understanding of pharmacology, I am committed to providing a comprehensive, patient-centered approach to healthcare.

I practice at <b>Verma Nursing Home</b>, conveniently located <b>at Fatehpur More in Nawada, Bihar.</b> At our clinic, we pride ourselves on delivering specialized treatments tailored to meet each patient’s unique health needs. Our focus is on creating a comfortable and trustworthy environment where quality healthcare is accessible to all.

My goal is not only to treat but also to educate and empower patients to make informed decisions about their health. Whether you seek dental care, <b>pharmaceutical guidance</b>, or general health consultations, I am here to assist you on your journey to wellness with personalized and attentive care.

</p>
            </div>
        </div>
    );
};

export default About;
import React from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './ConfirmBooking.css';

export default function BookingConfirmation() {
  const navigate = useNavigate();

  return (
    <Container className="container-center">
      <Card className="card-custom text-center">
        <Card.Body>
          <div className="mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              fill="currentColor"
              className="bi bi-check-circle check-icon"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <Card.Title as="h2">Booking Confirmed!</Card.Title>
          <Card.Text>
            Thank you for choosing our hospital. Your booking has been successfully confirmed.
          </Card.Text>
          <Card.Text className="text-muted">
            We will reach out to you by phone to provide further details about your appointment.
          </Card.Text>
          <Button variant="outline-primary" onClick={() => navigate('/')}>
            Return to Home
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
}

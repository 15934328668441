import React from 'react';

const Blog = () => {
    return (
        <div>
            <div className="container py-5">
                <div>
                    <h2 className='txt-primary'>The Role of Pharmacology in Dental Surgery</h2>
                
                <p>Overview:</p>
                <p> Explore how pharmacology supports dental procedures, including anesthesia, pain management, and infection control. Discuss the types of medications commonly used and their purposes in dental care.</p>
<p>Key Points:</p>
<p>
Common medications prescribed after dental surgery (antibiotics, painkillers)
The importance of understanding interactions between dental drugs and other medications
How pharmacists ensure patient safety through medication management</p>
                </div>
                
                <div>
                    <h2 className='txt-primary'>Why are you using firebase? What other options do you have to implement authentication?</h2>
                    <p>I am using Firebase as it  provides all in one backend services like database, authentication  using passwords, phone numbers, and cloud storage as well as it gives ready-made UI libraries. <br />

                        Other options do you have to implement authentication:
                        Multi-factor authentication: Two or more independent methods are required to identify a user.
                        Certificate-based authentication:In Certificate-based authentication, technology uses digital certificates to identify users, machines, or devices. A digital certificate is an electronic document based on the idea of ​​a driver's license or  passport.</p>
                </div>
                <div>
                    <h2 className='txt-primary'>What other services does firebase provide other than authentication?</h2>
                    <p>Firebase provides other than authentication: 1) Databases such as Firebase Realtime Database that use data synchronization. Whenever data changes, all connected devices will receive the update within milliseconds. It provides a collaborative and immersive experience without having to think about network code, Cloud Firestore, Cloud Storage that stores files like images, videos, and audio; 2) Firebase testing services such as Test Lab, Crashlytics, Performance monitoring, App Distribution; 3) Fast hosting service that hosts HTML, CSS, JavaScript, graphics, fonts, and icons.</p>
                </div>
                
            </div>
        </div>
    );
};

export default Blog;